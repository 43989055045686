import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import xss from 'xss'
import cl from '../../classnames.scss'
import {Subtitle} from '../../subtitle'
import {getElementStyle} from '../../../../../../commons/utils/dom'
import s from './about-section.scss'
import {AboutSectionProps} from './index'

export interface AboutSectionState {
  collapsed: boolean
  overflows: boolean
  readMoreButtonHeight: number
}

const WRAPPER_ID = 'events-details-page-about-wrapper'
const INNER_ID = 'events-details-page-about-inner'
const BUTTON_ID = 'events-details-page-about-read-more-btn'

const readMoreMargin = 12
const maxHeight = '14em'

export class AboutSection extends React.Component<AboutSectionProps, AboutSectionState> {
  constructor(props: AboutSectionProps) {
    super(props)

    this.state = {
      collapsed: this.props.readMoreButtonVisible,
      overflows: false,
      readMoreButtonHeight: 0,
    }
  }

  componentDidMount() {
    this.setOverflow()
  }

  componentDidUpdate(props: AboutSectionProps, state: AboutSectionState) {
    if (this.props.aboutText !== props.aboutText) {
      this.setOverflow()
    }

    if (this.props.readMoreButtonVisible !== props.readMoreButtonVisible) {
      const overflows = this.props.readMoreButtonVisible ? false : state.overflows
      this.setState({collapsed: this.props.readMoreButtonVisible, overflows})
    }

    if (this.state.collapsed !== state.collapsed) {
      if (this.props.isEditor) {
        this.setOverflow()
      } else {
        this.props.updateLayout()
      }
    }
  }

  toggle = () => this.setState({collapsed: !this.state.collapsed})

  setOverflow() {
    const wrapper = document.getElementById(WRAPPER_ID)
    const inner = document.getElementById(INNER_ID)
    const wrapperHeight = parseInt(getElementStyle(wrapper, 'height'), 10)
    const innerHeight = parseInt(getElementStyle(inner, 'height'), 10)

    if (this.props.readMoreButtonVisible) {
      const button = document.getElementById(BUTTON_ID)
      const readMoreButtonHeight = parseInt(getElementStyle(button, 'height'), 10)
      const overflows = inner && wrapper && innerHeight > wrapperHeight

      this.setState({overflows, collapsed: true, readMoreButtonHeight})
    } else {
      this.setState({overflows: false, collapsed: false})
    }
  }

  render() {
    const {
      compact,
      isMobile,
      eventId,
      aboutTitleText,
      readLessText,
      readMoreText,
      readMoreButtonExtraMargin,
      isEditor,
      aboutText,
      t,
    } = this.props

    const {collapsed, overflows, readMoreButtonHeight} = this.state
    const wrapperStyle = {
      maxHeight: collapsed ? maxHeight : undefined,
      height:
        overflows && collapsed ? `calc(${maxHeight} - ${readMoreButtonHeight}px - ${readMoreMargin}px)` : undefined,
    }

    return (
      <div
        className={classNames(
          s.container,
          {
            [s.mobileContainer]: isMobile,
            [s.largerBottomMargin]: readMoreButtonExtraMargin,
          },
          cl.evTextFont,
          cl.evTextColor,
        )}
        data-hook={DH.ABOUT_SECTION}
      >
        <Subtitle
          className={classNames({[s.mobileSubtitle]: isMobile})}
          dataHook="about"
          text={aboutTitleText}
          compact={compact}
        />
        <div
          data-hook={DH.ABOUT_SECTION_WRAPPER}
          id={WRAPPER_ID}
          className={classNames(s.wrapper)}
          style={wrapperStyle}
        >
          <div
            id={INNER_ID}
            data-hook={DH.ABOUT_SECTION_TEXT}
            className={s.aboutText}
            role="text"
            key={eventId}
            dangerouslySetInnerHTML={{
              __html: xss(isEditor && !aboutText ? t('demoEvent_aboutSectionText') : aboutText),
            }}
          />
        </div>
        <button
          id={BUTTON_ID}
          data-hook={DH.ABOUT_SECTION_BUTTON}
          aria-controls={INNER_ID}
          aria-expanded={!collapsed}
          className={classNames(s.readMore, {
            [s.hidden]: !this.state.overflows,
          })}
          onClick={overflows ? this.toggle : null}
        >
          {collapsed ? readMoreText : readLessText}
        </button>
      </div>
    )
  }
}
