import React from 'react'
import classNames from 'classnames'
import s from '../order-summary.scss'
import {getSummaryTicketPrice} from '../../../../selectors/placed-order'
import {useCurrencyFormatter} from '../../../../../../../commons/hooks/currency'
import {TicketProps} from './index'

export const Ticket = ({ticket, t}: TicketProps) => {
  const quantity = ticket.orderedCount
  const {getFormattedMoney} = useCurrencyFormatter()
  const {labeledPrice, total} = getSummaryTicketPrice(ticket, t, getFormattedMoney)

  return (
    <div className={s.ticketInfo}>
      <div className={s.ticketName}>{ticket.name}</div>
      <div className={classNames(s.secondaryText, s.smallText, s.ticketPrice)}>{labeledPrice}</div>
      <div className={s.spreadRow}>
        <div className={classNames(s.secondaryText, s.smallText)}>{t('mobile.thanks.quantity', {quantity})}</div>
        <div>{total}</div>
      </div>
      <div className={s.divider} />
    </div>
  )
}
